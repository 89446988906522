<template>
  <div>
    <v-overlay
      v-if="showDialog || showCookiesManager"
      z-index="200"
    ></v-overlay>
    <div v-if="showDialog && !showCookiesManager" class="content">
      <v-container>
        <div class="d-flex flex-column flex-md-row justify-center align-center">
          <p class="text">
            {{ $t("COOKIE_CONSENT.TEXTA") }}
            <a href="/privacy" class="my-link">{{
              $t("COOKIE_CONSENT.LINK")
            }}</a>
            {{ $t("COOKIE_CONSENT.TEXTB") }}.
          </p>
          <div class="button agree" @click="acceptCookies()">
            {{ $t("COOKIE_CONSENT.AGREE") }}
          </div>
          <div class="button decline" @click="openSettings()">
            {{ $t("COOKIE_CONSENT.MANAGE") }}
          </div>
        </div>
      </v-container>
    </div>
    <CookiesManager
      v-if="showCookiesManager"
      :types="allTypes"
      @acceptCookies="acceptCookies"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CookiesManager from "./CookiesManager.vue";

export default {
  name: "CookieFooter",
  components: { CookiesManager },
  data() {
    return {
      showDialog: false,
      allTypes: [
        {
          value: "mandatory",
          label: this.$t("COOKIE_CONSENT.TYPE.MANDATORY")
        },
        {
          value: "functional",
          label: this.$t("COOKIE_CONSENT.TYPE.FUNCTIONAL")
        },
        {
          value: "analytics",
          label: this.$t("COOKIE_CONSENT.TYPE.ANALYTICS")
        },
        {
          value: "advertisement",
          label: this.$t("COOKIE_CONSENT.TYPE.ADVERTISEMENT")
        }
      ],
      acceptedCookies: []
    };
  },
  computed: {
    ...mapState(["cookiesConsent", "showCookiesManager"])
  },
  mounted() {
    if (this.cookiesConsent && this.cookiesConsent.types) {
      this.acceptedCookies = [...this.cookiesConsent.types];
    } else {
      this.acceptedCookies = [];
    }
    this.loadGTM();
    if (!this.cookiesConsent || !this.cookiesConsent.accepted) {
      this.showDialog = true;
    } else {
      this.loadCookies();
    }
  },
  methods: {
    ...mapMutations(["setCookiesConsent", "setCookiesManager"]),

    openSettings() {
      this.setCookiesManager(true);
    },

    loadHotJar() {
      (function(h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function() {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5096708, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    },

    loadPixel() {
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      window.fbq("init", "582708920083048");
      window.fbq("track", "PageView");
    },

    gtag() {
      window.dataLayer.push(arguments);
    },

    loadGTM() {
      window.dataLayer = window.dataLayer || [];
      this.gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied"
      });
      this.gtag("set", "url_passthrough", true);
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-M9CTKCG");
    },

    loadCookies() {
      const hasAnalytics = this.cookiesConsent.types.includes("analytics");
      const hasAdvertisement = this.cookiesConsent.types.includes(
        "advertisement"
      );

      if (hasAnalytics) {
        this.loadHotJar();
      }

      if (hasAdvertisement) {
        this.loadPixel();
      }

      this.gtag("consent", "update", {
        ad_storage: hasAdvertisement ? "granted" : "denied",
        ad_user_data: hasAdvertisement ? "granted" : "denied",
        ad_personalization: hasAdvertisement ? "granted" : "denied",
        analytics_storage: hasAnalytics ? "granted" : "denied"
      });

      this.hideDialogs();
    },

    acceptCookies(data) {
      const all = !data;
      const cookies = this.determineCookiesToAccept(all, data);
      this.applyCookieSettings(cookies);
    },

    determineCookiesToAccept(all, cookies) {
      return all ? this.acceptAllCookies() : cookies;
    },

    acceptAllCookies() {
      return this.allTypes.map(type => type.value);
    },

    applyCookieSettings(cookies) {
      this.setCookiesConsent({ accepted: true, types: cookies });
      this.loadCookies();
    },

    hideDialogs() {
      this.showDialog = false;
      this.setCookiesManager(false);
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  background-color: white;
  z-index: 210;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.button {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: normal;
  margin: 5px;
  padding: 10px;
  &:hover {
    color: white;
    opacity: 0.7;
  }

  @media screen and (min-width: 960px) {
    margin: 0 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }
}

.text {
  padding: 20px;
  @media screen and (min-width: 960px) {
    max-width: 70%;
  }
}

.btn {
  background-color: red !important;
  color: white;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px !important;
}

.agree {
  background-color: #ff0000;
}

.decline,
.save {
  background-color: rgb(161, 159, 159) !important;
}

.my-link {
  color: #ff0000;
  text-decoration: none;
}
</style>
