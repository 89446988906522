<template>
  <div class="basket-wrapper">
    <page-header
      :text="$t('HEADER.BASKET')"
      font="white"
      back="black"
    ></page-header>

    <the-loader v-if="showLoader" />

    <div v-else>
      <empty-basket v-if="isBasketEmpty" />

      <b-container v-else class="wrapper">
        <div class="basket-main">
          <basket-item
            v-for="(item, index) in linesToRender"
            :key="index"
            :symbol="selectedCart.shoppingcart.currency.symbol"
            :cart-id="selectedCart.shoppingcart._id"
            :object="item"
            :kit-components="
              selectedCart.shoppingcart.transactionlines.filter(
                l => l.init === item._id && l.item.recordtype !== 'service'
              )
            "
            @error="error"
            @delete="handleDelete"
            @deletingStart="loading = true"
            @deletingStop="loading = false"
          ></basket-item>

          <contact-info ref="contact" v-model="contactInfo" />

          <shipping-address
            ref="address"
            v-model="shippingaddressSelected"
            :shipping-methods="filteredShippingMethods"
            :payment-methods="paymentMethods"
          />

          <b-form-checkbox
            id="is-billing-different"
            ref="isBillingDifferent"
            v-validate
            v-model="billingAddress.isDifferent"
            class="mb-4"
            name="isBillingDifferent"
          >
            {{ $t("BASKET.BILLING_DIFFERENT") }}
          </b-form-checkbox>

          <different-billing
            ref="differentBillingForm"
            v-model="billingAddress"
          />

          <the-company
            v-if="billingAddress.isDifferent"
            ref="company"
            v-model="isCompany"
            :cart-id="cart_id"
            :country="this.billingAddress.country"
          />

          <additional-info v-model="memo" />

          <terms-acceptance ref="terms" v-model="terms" />

          <hr />

          <v-btn
            color="#ff5d03"
            block
            :disabled="loadingOrder || loading"
            :loading="loadingOrder || loading"
            class="button my-6"
            @click="placeOrder"
            >{{ $t("BASKET.PLACE_ORDER") }}
          </v-btn>
        </div>

        <div v-if="basketQuantity > 0" class="basket-aside">
          <side-info
            :cart="selectedCart"
            :includes-service="includesService"
            :summary="selectedCart.shoppingcart"
            :symbol="selectedCart.shoppingcart.currency.symbol"
            :transactionlines="selectedCart.shoppingcart.transactionlines"
            :loading="loading"
            @promoApplied="handlePromoCode"
          ></side-info>
        </div>
      </b-container>
    </div>
    <ChangeCurrencyBasket
      :changeCurrency="this.showChangeCurrencyModal"
      :currency="this.currency"
      @clicked="updateCurrencyBasket"
    />
    <universal-modal :modal="modal" @closeModal="closeModal" />
  </div>
</template>

<script>
import { euCountries } from "@/euCountries";
import { mapState, mapMutations } from "vuex";
import api from "@/api2";
import cfg from "@/3c.config";
import axios3C from "@/axios";
import PageHeader from "@/components/common/PageHeader.vue";
import BasketItem from "@/components/basket/BasketItem.vue";
import UniversalModal from "@/components/common/UniversalModal.vue";
import SideInfo from "@/components/basket/SideInfo.vue";
import ShippingAddress from "@/components/basket/Shipping.vue";
import ContactInfo from "@/components/basket/ContactInfo";
import AdditionalInfo from "@/components/basket/AdditionalInfo";
import TheLoader from "@/components/common/Loader";
import EmptyBasket from "@/components/basket/EmptyBasket";
import TheCompany from "@/components/basket/Company";
import TermsAcceptance from "@/components/basket/TermsAcceptance";
import DifferentBilling from "@/components/basket/DifferentBilling";
import ChangeCurrencyBasket from "../components/basket/ChangeCurrencyBasket";

const DEFAULT_BASKET_IDX = 0;
const TAX = {
  ZERO: "5e0dbaba9e33df43f0b3a480",
  STANDARD: "5e0dbaba9e33df43f0b3a47f"
};

export default {
  name: "BasketView",
  components: {
    AdditionalInfo,
    ContactInfo,
    PageHeader,
    BasketItem,
    UniversalModal,
    SideInfo,
    ShippingAddress,
    DifferentBilling,
    TheLoader,
    EmptyBasket,
    TheCompany,
    TermsAcceptance,
    ChangeCurrencyBasket
  },
  data() {
    return {
      isBillingDifferent: false,
      isCompany: false,
      fieldsDisabled: false,
      carts: [],
      validated: false,
      showChangeCurrencyModal: false,
      memo: "",
      cfg,
      axios3C,
      terms: [],
      summary: null,
      showLoader: false,
      loading: false,
      loadingOrder: false,
      contactInfo: {
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
        companyName: ""
      },
      shippingaddressSelected: {
        address: "",
        numer: "",
        address2: "",
        city: "",
        country: "",
        zip: "",
        shippingMethod: "",
        paymentMethod: ""
      },
      billingAddress: {
        isDifferent: false,
        firstName: "",
        lastName: "",
        companyName: "",
        address: "",
        numer: "",
        address2: "",
        city: "",
        country: "",
        zip: ""
      },
      modal: {
        header: "Server error",
        text: "",
        show: false,
        code: ""
      }
    };
  },
  computed: {
    ...mapState(["cart_id", "basketQuantity", "lang", "currency"]),
    isBasketEmpty() {
      return this.basketQuantity === 0 || !this.cart_id;
    },
    linesToRender() {
      return this.selectedCart.shoppingcart.transactionlines.filter(
        line => line.item.recordtype !== "service" && !line.kitcomponent
      );
    },
    isFromEu() {
      return euCountries.includes(this.shippingaddressSelected.country);
    },
    taxId() {
      return this.isCompany || !this.isFromEu ? TAX.ZERO : TAX.STANDARD;
    },
    filteredShippingMethods() {
      return (
        (this.selectedCart &&
          this.selectedCart.options.shippingmethod.map(obj => {
            if (obj.displayname !== obj.description) {
              obj.displayname = `${obj.displayname} - ${obj.description}`;
            }
            return obj;
          })) ||
        []
      );
    },
    paymentMethods() {
      return (
        (this.selectedCart && this.selectedCart.options.paymentmethod) || []
      );
    },
    includesService() {
      if (this.selectedCart.shoppingcart) {
        const item = this.selectedCart.shoppingcart.transactionlines.find(
          line => line.item.recordtype === "service"
        );
        return !!(item && item.price > 0);
      }
      return false;
    },
    linesWithPrice() {
      if (this.selectedCart) {
        return this.selectedCart.shoppingcart.transactionlines.filter(
          item => item.price !== 0
        );
      }
      return [];
    },
    selectedCart() {
      return this.carts[DEFAULT_BASKET_IDX];
    }
  },
  created() {
    this.updateField("currency", this.currency);

    if (this.cart_id) {
      this.showLoader = true;

      if (this.cart_id) {
        this.showLoader = true;
        api
          .getCarts(this.cart_id, this.axios3C, this.currency)
          .then(res => {
            this.extractDataFromResponse(res.data);
          })
          .catch(err => {
            this.error(err);
          })
          .finally(() => {
            this.showLoader = false;
          });
      }
    }
  },
  methods: {
    ...mapMutations(["setCurrency"]),
    async updateCurrencyBasket(change, newCurrency) {
      if (change) {
        this.setCurrency(newCurrency);
      }
      this.showChangeCurrencyModal = false;
    },
    handlePromoCode(code) {
      this.updateField("promocode", code);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    error(err) {
      const error = (err.response && err.response.data) || err;
      if (error.code === "AUTH001") {
        this.$store.commit("logOut");
      } else {
        this.modal.header = "Server error";
        this.modal.text = error.message || error.errmsg || error;
        this.modal.code = error.code;
        this.modal.show = true;
      }
    },
    handleDelete(data) {
      this.extractDataFromResponse(data);
    },
    update3CField(field, value) {
      return this.axios3C
        .post("/cart", {
          action: "updateCart",
          field,
          cart_id: this.cart_id,
          value,
          customer: "guest"
        })
        .then(res => res.data);
    },
    bulkUpdate(changes) {
      this.loading = true;
      return this.axios3C
        .post("/cart", {
          action: "updateCart",
          changes,
          cart_id: this.cart_id,
          refresh: true,
          customer: "guest"
        })
        .then(res => {
          this.extractDataFromResponse(res.data);
        })
        .catch(err => {
          this.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateField(field, value) {
      this.loading = true;
      return this.axios3C
        .post("/cart", {
          action: "updateCart",
          field,
          cart_id: this.cart_id,
          value,
          customer: "guest"
        })
        .then(res => {
          this.extractDataFromResponse(res.data);
        })
        .catch(err => {
          this.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async placeOrder() {
      const valid = await isFormValid.bind(this)();
      if (!valid) {
        return;
      }

      try {
        this.loadingOrder = true;
        await saveOrderData.bind(this)();
        const orderId = await createOrder.bind(this)();
        if (orderId) {
          fireGAPurchaseEvent.bind(this)(orderId);
          displayOrder.bind(this)(orderId);
        }
      } catch (err) {
        this.error(err);
      } finally {
        this.loadingOrder = false;
      }

      async function isFormValid() {
        const isContactValid = await this.$refs.contact.$validator.validate();
        const isAddressValid = await this.$refs.address.$validator.validate();
        const isBillingValid =
          !this.billingAddress.isDifferent ||
          (await this.$refs.differentBillingForm.$validator.validate());
        const isTermsValid = await this.$refs.terms.$validator.validate();
        const isCompanyValid =
          !this.billingAddress.isDifferent ||
          (await this.$refs.company.$validator.validate());
        const areAllFieldsValid =
          isContactValid &&
          isAddressValid &&
          isTermsValid &&
          isCompanyValid &&
          isBillingValid;

        if (!areAllFieldsValid) {
          this.modal.header = "Please check all fields";
          this.modal.text = "All required fields must be filled";
          this.modal.show = true;
          return false;
        }
        return true;
      }

      async function saveOrderData() {
        const [addressId, billingId] = await createAddresses.bind(this)();
        return update3CFields.bind(this)(addressId, billingId);
      }
      async function createAddresses() {
        const addressId = await createAddress.bind(this)();
        const billingId = await createBillingAddress.bind(this)();
        return [addressId, billingId];
      }

      async function createAddress() {
        const {
          address,
          numer,
          address2,
          city,
          country,
          zip
        } = this.shippingaddressSelected;
        let payload = {
          address: `${address} ${numer}`,
          address2,
          addressee: `${this.contactInfo.firstName} ${this.contactInfo.lastName}`,
          city,
          zip,
          country,
          phone: this.contactInfo.phone,
          name:
            this.contactInfo.companyName ||
            `${this.contactInfo.firstName} ${this.contactInfo.lastName}`
        };
        return this.axios3C
          .post("/cart", {
            ...payload,
            customer: "guest",
            _id: "",
            action: "updateAddress",
            cart_id: this.cart_id
          })
          .then(res => res.data._id);
      }

      async function createBillingAddress() {
        if (this.billingAddress.isDifferent) {
          const {
            address,
            numer,
            address2,
            city,
            country,
            zip,
            companyName
          } = this.billingAddress;
          let payload = {
            address: `${address} ${numer}`,
            address2,
            city,
            zip,
            country,
            name: companyName
          };
          return this.axios3C
            .post("/cart", {
              ...payload,
              customer: "guest",
              _id: "",
              action: "updateAddress",
              cart_id: this.cart_id
            })
            .then(res => res.data._id);
        } else {
          const {
            address,
            address2,
            city,
            country,
            zip
          } = this.shippingaddressSelected;
          const { firstName, lastName } = this.contactInfo;
          let payload = {
            address,
            address2,
            addressee: `${firstName} ${lastName}`,
            city,
            zip,
            country,
            name: `${firstName} ${lastName}`
          };
          return this.axios3C
            .post("/cart", {
              ...payload,
              customer: "guest",
              _id: "",
              action: "updateAddress",
              cart_id: this.cart_id
            })
            .then(res => res.data._id);
        }
      }

      async function update3CFields(addressId, billingId) {
        if (this.memo) {
          await this.update3CField("comment", this.memo);
        }
        await this.update3CField("sendtogether", true);
        await this.update3CField("reqpayment", true);
        await this.update3CField("shippingaddress", addressId);
        await this.update3CField("billingaddress", billingId);
        await this.update3CField("shipemail", this.contactInfo.email);
      }

      async function createOrder() {
        return this.axios3C
          .post("/cart", {
            action: "addOrder",
            cart_id: this.cart_id,
            customer: "guest"
          })
          .then(res => res.data._id);
      }

      function fireGAPurchaseEvent(orderId) {
        this.$gtag.purchase({
          transaction_id: orderId,
          affiliation: "OZ4X4",
          value: this.selectedCart.shoppingcart.grossamount,
          currency: this.currency,
          tax: this.selectedCart.shoppingcart.taxamount,
          shipping: this.selectedCart.shoppingcart.grossshippingcost,
          items: this.linesWithPrice.map(line => ({
            id: line._id,
            name: line.displayname,
            quantity: line.quantity,
            price: line.grosamount
          }))
        });
      }
      function displayOrder(orderId) {
        this.$router.push("/order/" + orderId);
      }
    },
    closeModal() {
      if (this.modal.code === "AUTH001") {
        this.$store.commit("logOut");
        this.$router.push("/login");
      }
      this.modal.show = false;
    },
    extractDataFromResponse(data) {
      this.carts = data.shoppingcarts;
      this.summary = data.summary;
    }
  },
  watch: {
    isCompany: function(value) {
      this.updateField("tax", value ? TAX.ZERO : TAX.STANDARD);
    },
    "shippingaddressSelected.country": async function(country) {
      if (
        (this.currency !== "pln" && country === "PL") ||
        (country !== "PL" && this.currency === "pln")
      ) {
        this.showChangeCurrencyModal = true;
      }

      await this.bulkUpdate([
        { field: "shipcountry", value: country },
        { field: "shippingmethod", value: "" },
        { field: "paymentmethod", value: "" }
      ]);
      await this.updateField("tax", this.taxId);

      this.shippingaddressSelected.shippingMethod = "";

      await new Promise(resolve => setTimeout(resolve, 250));

      this.shippingaddressSelected.paymentMethod = "";
    },
    "shippingaddressSelected.shippingMethod": async function(method) {
      await this.updateField("shippingmethod", method);
    },
    "shippingaddressSelected.paymentMethod": async function(method) {
      await this.updateField("paymentmethod", method);
    },
    "shippingaddressSelected.zip": async function(value) {
      await this.updateField("shipzip", value);
    },
    "billingAddress.country": async function(value) {
      await this.updateField("billcountry", value);
    }
  }
};
</script>

<style lang="scss">
.basket-wrapper {
  font-family: "Montserrat", sans-serif;

  .button {
    color: white;
    font-size: 1.1em;
    font-weight: bolder;
  }

  .cart-switcher {
    margin-bottom: 20px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 20px auto;

    @media screen and (max-width: 960px) {
      grid-template-columns: auto;
      text-align: center;
    }

    .basket-main {
      padding-right: 20px;

      @media screen and (max-width: 960px) {
        padding: 0;
      }
    }
  }
}
</style>
