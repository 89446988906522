<template>
  <div class="section-company">
    <b-form-checkbox
      id="is-company"
      ref="isCompany"
      v-validate
      class="mb-4"
      name="isCompany"
      :checked="value"
      @input="handleChange"
    >
      {{ $t("BASKET.NO_VAT") }}
    </b-form-checkbox>

    <b-form-group v-show="value" :invalid-feedback="veeErrors.first('vat')">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text class="bg-transparent">
            <b>{{ country }}</b>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          id="vat"
          v-validate="`required_if:isCompany,true|vat:${cartId},${country}`"
          data-vv-delay="500"
          name="vat"
          v-model="vat"
          type="text"
          :placeholder="$t('BASKET.VAT_NUMBER')"
          :state="validateState('vat')"
        ></b-form-input>
      </b-input-group>
      <b-form-text class="vat-note" v-if="hasCountryPrefix">
        <font-awesome-icon icon="info-circle" />
        Please enter your VAT number without country prefix
      </b-form-text>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "TheCompany",

  props: {
    cartId: {
      type: String,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    },
    country: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      vat: ""
    };
  },

  computed: {
    hasCountryPrefix() {
      return this.vat && /^[A-Za-z]/.test(this.vat);
    }
  },

  methods: {
    handleChange(value) {
      this.$emit("input", !!value);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  },

  watch: {
    country: function() {
      this.vat = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.vat-note {
  margin-top: 0.5rem;
  padding: 5px 10px;
  background-color: #f8f9fa;

  color: #495057;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 0 4px 4px 0;
}

.vat-note svg {
  color: #208cff;
  margin-right: 5px;
}

.vat-note:hover {
  background-color: #e9ecef;
  transition: background-color 0.2s ease;
}
</style>
